.app {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bioScroller {
  display: flex;
  flex: 1;
  overflow-y: auto;
  background-color: black;
}

.bioContent {
  margin: 5vh 3vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: beige;
}

.bioContent::-webkit-scrollbar {
  display: none;
}

.canvasContainer {
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: repeating-linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5) 0px,
      rgba(0, 0, 0, 0.2) 1px,
      rgba(0, 0, 0, 0) 3px
    );
    opacity: 0.3;
  }
}

.canvas {
  filter: blur(1.1px);
}

.socialBar {
  position: absolute;
}

// Reusable
.border-box {
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  // source: http://css-tricks.com/box-sizing/
}

.topRight {
  top: 0;
  right: 0;
}

.topLeft {
  top: 0;
  left: 0;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

// Small screens
@media only screen and (max-width: 700px) {
  .app {
    flex-direction: column-reverse;
  }

  .hide-if-small {
    display: none;
  }
}

// Large screen
@media only screen and (min-width: 700px) {
  .bioScroller {
    height: 100vh;
    width: 55%;
  }

  .bioContent {
    min-height: 90vh;
    justify-content: center;
  }

  .canvasContainer {
    height: 100vh;
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  .show-if-small {
    display: none;
  }
}