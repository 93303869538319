/* || CONSTANTS */

$font-stack: "Nunito", -apple-system, BlinkMacSystemFont;

/* || GENERAL STYLES */

body {
  margin: 0;
  padding: 0;
  font-size: 1.6em;
  font-family: $font-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

::selection {
  background-color: #FFC107;
  color: #F44336;
}

a {
  color: beige;
}

h1 {
  margin: 0;
  font-weight: normal;
  font-size: 1em;
}


